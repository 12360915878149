<script setup lang="ts">
import { NodeViewContent } from '@tiptap/vue-3';
import { computed, nextTick, ref, watch } from 'vue';
import { SwToggle } from '@swimm/ui';
import { BaseButton, BaseIcon, BaseProse } from '@swimm/reefui';

const props = defineProps<{
  isEditMode: boolean;
  isEmpty: boolean;
  error: string | null;
  svg: string | null;
  shouldShowMermaidEmptyState: boolean;
  editorOverlapped: boolean;
  placeholder: string | null;
  isSideBySideView: boolean;
  showEditor: boolean;
}>();

const mainDirection = computed(() => (props.isSideBySideView && props.showEditor ? 'row-reverse' : 'column'));

const panZoomEnabled = ref(false);

watch(panZoomEnabled, (enabled) => {
  if (!enabled) {
    currentSvgPanZoom?.reset();
    currentSvgPanZoom?.fit();
    currentSvgPanZoom?.center();
    currentSvgPanZoom?.disablePan();
    currentSvgPanZoom?.disableZoom();
  } else {
    currentSvgPanZoom?.enablePan();
    currentSvgPanZoom?.enableZoom();
  }
});

let svgPanZoom: typeof import('svg-pan-zoom') | null = null;
let currentSvgPanZoom: ReturnType<typeof import('svg-pan-zoom')> | null = null;

const svgContainer = ref<HTMLElement | null>();

const onZoomInClick = () => {
  currentSvgPanZoom?.zoomIn();
};

const onZoomOutClick = () => {
  currentSvgPanZoom?.zoomOut();
};

const onResetClick = () => {
  currentSvgPanZoom?.reset();
  currentSvgPanZoom?.fit();
  currentSvgPanZoom?.center();
};

watch(
  () => [props.svg, svgContainer.value] as const,
  async ([svg, svgContainer], _, onCleanup) => {
    if (!svg || !svgContainer) {
      return;
    }
    // We need to do a dynamic import as this is a browser-only library.
    svgPanZoom ??= (await import('svg-pan-zoom')).default;
    // The SVG element will only be available after the next tick after the svg property was updated.
    nextTick(() => {
      const svgElement = svgContainer.childNodes[0] as SVGSVGElement | null;
      if (!svgElement) {
        return;
      }
      // The viewBox property contains the actual height of the diagram, but svgPanZoom removes this property
      // (see https://github.com/bumbu/svg-pan-zoom?tab=readme-ov-file#svg-height-is-broken) - so we extract the height
      // and apply it as a style.
      const svgHeight = svgElement.viewBox.animVal.height;
      svgElement.style.height = `calc(${svgHeight}px + var(--space-small) * 2)`; // Add some padding
      // We also remove the maxWidth (which 'hugs' the diagram) so that the entire mermaid viewport is available for the
      // user to zoom in.
      svgElement.style.maxWidth = '';
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentSvgPanZoom = svgPanZoom!(svgElement, {
        zoomEnabled: panZoomEnabled.value,
        // We'd rather have the user be able to scroll up/down since it's more comfortable in longer, vertical diagrams
        // such as flow diagrams, which we expect to be the most common.
        mouseWheelZoomEnabled: false,
        panEnabled: panZoomEnabled.value,
        // We have our own controls.
        controlIconsEnabled: false,
        zoomScaleSensitivity: 0.6,
        fit: true,
        center: true,
        minZoom: 0.1,
        maxZoom: 10,
      });
    });
    onCleanup(() => {
      currentSvgPanZoom?.destroy();
      currentSvgPanZoom = null;
    });
  },
  { immediate: true }
);

// We need to let svg-pan-zoom know when its container changes size, which happens when svg changes (handled above) and
// when the editor is shown/hidden and we switch from full view of the mermaid to the side-by-side view.
watch(
  () => [props.showEditor, props.isSideBySideView],
  () => {
    // Wait for the layout change to happen before updating the SVG Pan Zoom.
    nextTick(() => {
      currentSvgPanZoom?.updateBBox();
      currentSvgPanZoom?.resize();
      currentSvgPanZoom?.fit();
      currentSvgPanZoom?.center();
    });
  }
);
</script>
<template>
  <div class="mermaid-panels">
    <div
      :contenteditable="false"
      :class="[
        'container',
        {
          clickable: isEditMode,
          'pan-zoom-enabled': panZoomEnabled,
          'side-container': isSideBySideView && showEditor,
          'top-container': !isSideBySideView && showEditor,
        },
      ]"
    >
      <div
        v-if="svg"
        :contenteditable="false"
        class="diagram"
        :class="{ 'invalid-diagram': !!error, 'empty-state': shouldShowMermaidEmptyState }"
        data-testid="mermaid-diagram"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <pre class="mermaid" v-html="svg" ref="svgContainer"></pre>
        <div class="pan-zoom-controls">
          <div class="pan-zoom-control-box toggle-box" @mousedown.prevent="() => (panZoomEnabled = !panZoomEnabled)">
            <SwToggle class="pan-zoom-control-toggle" size="xsmall" :value="panZoomEnabled" />
            <BaseProse size="small">Pan & zoom</BaseProse>
          </div>
          <div v-if="panZoomEnabled" class="pan-zoom-control-box zoom-box">
            <BaseButton variant="tertiary" size="small" @click="onZoomInClick">
              <template #leftIcon><BaseIcon name="add" /></template>
            </BaseButton>
            <BaseButton variant="tertiary" size="small" @click="onResetClick"> Reset </BaseButton>
            <BaseButton variant="tertiary" size="small" @click="onZoomOutClick">
              <template #leftIcon><BaseIcon name="minimize" /></template>
            </BaseButton>
          </div>
        </div>
      </div>
      <div v-if="shouldShowMermaidEmptyState" class="mermaid-edit-empty-state"></div>
    </div>
    <div
      class="editor"
      :class="{
        'editor-overlapped': editorOverlapped,
        'hide-editor': !showEditor,
        'side-editor': isSideBySideView && showEditor,
      }"
    >
      <NodeViewContent
        id="mermaid-content"
        as="pre"
        data-testid="mermaid-content"
        :data-placeholder="placeholder"
        :contenteditable="isEditMode"
        class="mermaid content"
        :class="{ 'is-empty': isEmpty && showEditor }"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.mermaid-panels {
  > * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: v-bind(mainDirection);

  --mermaid-max-height: 45vh;

  .container {
    &.clickable {
      cursor: pointer;
    }

    &.pan-zoom-enabled {
      cursor: grab;
    }

    .diagram {
      position: relative;
      overflow: hidden;
      padding: 0;
      text-align: center;
    }

    .invalid-diagram {
      opacity: 0.2;
    }

    .mermaid-edit-empty-state {
      height: 140px;
      padding: var(--space-sm) var(--space-base);
    }

    &.side-container {
      border-left: 1px solid var(--color-border-default);
      flex-shrink: 0;
      flex-basis: 60%;
      border-radius: 0 4px 4px 0;

      .diagram {
        max-height: var(--mermaid-max-height);
        min-height: 25vh; /* Edit mode needs to be fixed in hight */
        overflow: auto;
      }
    }

    &.top-container {
      border-bottom: 1px solid var(--color-border-default);
    }
  }

  .mermaid {
    margin: 0;
    max-height: var(--mermaid-max-height);
    overflow-y: auto;
  }

  .mermaid > :deep(svg) {
    width: 100%;
  }

  .container:not(.pan-zoom-enabled) :deep(svg) {
    pointer-events: none;
  }

  .pan-zoom-controls {
    position: absolute;
    top: var(--space-xsmall);
    right: var(--space-xsmall);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--space-xsmall);

    .pan-zoom-control-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: var(--space-xxsmall) var(--space-xsmall);
      background-color: var(--color-bg-default);
      box-shadow: var(--box-shadow-small);
      border: 1px solid var(--color-border-default);
      border-radius: var(--border-radius);

      &.toggle-box {
        gap: var(--space-xsmall);
        cursor: pointer;

        & > .pan-zoom-control-toggle {
          pointer-events: none;
        }
      }
    }
  }

  .editor {
    top: 100%;
    width: 100%;
    z-index: 2;
    overflow: hidden;

    // Visually hidden CSS trick, https://css-tricks.com/inclusively-hidden/, so that the content can still be navigated to with the keyboard
    &.hide-editor {
      position: absolute;
      width: 1px;
      height: 1px;
      clip-path: inset(50%);
      overflow: hidden;
      white-space: nowrap;
    }

    &.editor-overlapped {
      pointer-events: none;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--text-color-secondary);
    }

    .content {
      padding: var(--space-sm) var(--space-base);
      font-family: var(--fontfamily-secondary);
      font-size: var(--body-S);
      line-height: var(--space-md);
      height: 140px;
      overflow: auto;
      background-color: var(--color-surface);

      &:focus-visible {
        outline: none;
      }

      &.is-empty::before {
        // TODO Proper color
        /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
        color: #adb5bd;
        content: attr(data-placeholder);
        /* stylelint-disable-next-line property-disallowed-list */
        float: left;
        height: 0;
        pointer-events: none;
      }
    }

    &.side-editor {
      display: flex;
      flex-direction: column;
      transform: scaleY(1);
      pointer-events: auto;
      flex-shrink: 0;
      flex-basis: 40%;

      &.editor-overlapped {
        pointer-events: none;
      }

      .content {
        flex: 1;
        max-height: var(--mermaid-max-height);
        min-height: 25vh;
        box-sizing: border-box;
        font-size: var(--body-XS);
        overflow: auto;
        margin: 0;
      }
    }
  }
}
</style>
