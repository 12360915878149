<template>
  <div>
    <SwModal :heading="modalTitle" :padded="false" :show-modal="show" @close="onCloseModalWithoutAddingRepo">
      <div class="content">
        <div v-if="!shouldShowConnectRepo">
          Connecting a repository to a workspace is restricted to workspace admins.
          <br />
          To connect a repo, contact an admin.
        </div>
        <div v-else-if="showReplaceProviderContent">
          <BaseSelect
            class="input"
            label="Git hosting platform"
            :options="GIT_HOSTING_OPTIONS"
            :model-value="gitHosting"
            :searchable="false"
            @input="onProviderSelect"
            append-to-body
            required
          />
          <Ribbon
            v-if="isSupportedRequiresHelp && gitHosting !== 'GitHub Enterprise Server'"
            hide-icon
            mode="web"
            class="enterprise-banner"
            >{{ gitHosting }} requires additional setup with the help of the Swimm team. You can explore Swimm now and
            talk to an expert when you are ready to set up your git hosting.</Ribbon
          >
          <div v-else-if="!isSelectedGitHostingSupported" class="error-message">
            {{
              gitHosting === 'Other'
                ? `Sorry, we only support the listed Git hosting platforms at the moment.`
                : `Sorry, we don't support ${gitHosting} at the moment.`
            }}
          </div>
          <Action class="replace-provider-btn" :disabled="!canChangeProvider" @click="onGitHostingChange">
            Change workspace Git hosting
          </Action>
          <div class="one-provider-support">
            Note: Only one Git hosting platform is currently supported per workspace.
          </div>
        </div>
        <div v-else-if="isPendingManualSetup">
          <SwText class="information-text">
            {{ pendingManualSetup.text }}
          </SwText>
          <Action secondary size="small" @click="pendingManualSetup.callToAction.action">{{
            pendingManualSetup.callToAction.buttonText
          }}</Action>
          <div class="change-provider-text" @click="showReplaceProviderOptions">
            {{ changeProviderNote }}
          </div>
        </div>
        <div v-else>
          <div v-if="isAuthorized">
            <SwText class="information-text">
              Choose a repository you wish to document. {{ providerScopeSelectionText }}
            </SwText>
            <div class="repo-search">
              <TextField
                :model-value="searchValue"
                @update:model-value="searchChanged"
                placeholder="Find repository"
                data-testid="add-repo-text-field"
              >
                <template #left>
                  <Icon name="search" />
                </template>
                <template #right v-if="loading">
                  <Loader secondary class="loader" />
                </template>
              </TextField>
            </div>
            <template v-if="!remoteRepos.length">
              <template v-if="loading">
                <RepoOptionSkeleton v-for="index in 6" :key="index" />
              </template>
              <div v-else class="centered no-results">
                <div class="headline3 bold">We didn’t find repositories in your {{ providerDisplayName }} account.</div>
                <div class="description" v-if="doesProviderSupportOnlyPublic">
                  Click “Manage scope” to check if your organization is authorized.
                </div>
              </div>
            </template>
            <div v-else class="repos-list">
              <EmptySearchResults v-if="!searchedRemoteRepos.length && !loading" class="search-container" />
              <template v-else>
                <div v-for="repo in searchedRemoteRepos" :key="generateRepoKey(repo.name, repo.owner)" class="repo-row">
                  <Checkbox
                    v-if="multiSelect"
                    size="small"
                    :model-value="isRepoSelected(repo)"
                    :data-testid="`add-repo-label-${repo.owner}-${repo.name}`"
                    @update:model-value="(isChecked) => updateRepoSelection(repo, isChecked)"
                    :disabled="isRepoSelectionDisabled(repo)"
                    ><SwText variant="body-L" class="repo-name"
                      ><Icon :name="repo.isPrivate ? 'private' : 'globe'" no-padding />{{
                        getDisplayName(repo)
                      }}</SwText
                    ></Checkbox
                  >
                  <RadioButton
                    v-else
                    v-model="selectedRepo"
                    :data-testid="`add-repo-label-${repo.owner}-${repo.name}`"
                    :value="generateRepoKey(repo.name, repo.owner)"
                    @click="selectRepo"
                  >
                    <SwText variant="body-L" class="repo-name"
                      ><Icon :name="repo.isPrivate ? 'private' : 'globe'" no-padding />{{
                        getDisplayName(repo)
                      }}</SwText
                    >
                  </RadioButton>
                </div>
                <SwText variant="body-S" weight="bold" v-if="showTooManyResultsMessage"
                  >Too many repositories, please filter using the search box above.</SwText
                >
                <Loader v-else-if="loading" secondary class="loader" />
              </template>
            </div>
            <div class="repo-scope" v-if="doesProviderSupportOnlyPublic">
              <p>Don’t see your repository?</p>
              <Action class="small" secondary @click="onManageScopeClick">Manage scope</Action>
            </div>
            <div class="manual-repo">
              <SwText v-if="doesProviderSupportOnlyPublic" variant="body-L"> Or insert repo URL </SwText>
              <SwText v-else variant="body-L"> Can't find your repo? </SwText>
              <TextField
                :model-value="repoLink"
                @update:model-value="repoLinkChanged"
                placeholder="Enter repository URL"
                class="manual-repo-test"
                :disabled="isDisableManualRepoInsert"
                :error="linkError"
              />
            </div>
            <div class="info-container">
              <Action
                :loading="loadingAddingRepos"
                :disabled="isConnectReposDisabled"
                class="connect-button"
                data-testid="connect-repo-button"
                @click="addRepos"
              >
                {{ connectRepoMessage }}
              </Action>
              <div class="help-text" @click="showReplaceProviderOptions">
                {{ changeProviderNote }}
              </div>
              <a class="help-text" href="https://docs.swimm.io/security" target="_blank" @click="openSecurityOverview">
                Security overview
              </a>
            </div>
          </div>
          <div v-else>
            <SwText variant="body-L" class="subtitle">
              In order to add a repo, please allow access to {{ providerDisplayName }} first.
            </SwText>
            <SwText
              v-if="workspaceProvider === GitProviderName.AzureDevops"
              variant="body-L"
              class="azure-oauth-help-section"
            >
              Before authorizing, please make sure that OAuth access is allowed in your organization's settings
              policies. For more information, see
              <a
                class="azure-oauth-help"
                href="https://learn.microsoft.com/en-us/azure/devops/organizations/accounts/change-application-access-policies?view=azure-devops#manage-a-policy"
                target="_blank"
              >
                OAuth Access</a
              >.
            </SwText>
            <div v-if="doesProviderSupportOnlyPublic" class="select-scope">
              <RadioButton v-model="scope" class="scope-item" value="private">
                <div>Select from all repositories</div>
                <div class="scope-subtext">Access to public and private</div>
              </RadioButton>
              <RadioButton v-model="scope" class="scope-item" value="public">
                <div>Select from public repositories</div>
                <div class="scope-subtext">Access to public only</div>
              </RadioButton>
            </div>
            <div class="info-container">
              <GitAuthorizeBanner
                class="auth-button"
                :provider="workspaceProvider"
                :button-text="providerButtonString"
                :allow-access-to-private-repos="scope === 'private'"
                button-size="big"
                context="Add repos modal"
                is-secondary
                use-provider-icon
                @authorized="authorizationClicked"
              ></GitAuthorizeBanner>
              <div class="change-provider-text" @click="showReplaceProviderOptions">
                {{ changeProviderNote }}
              </div>
            </div>
            <a class="help-text" href="https://docs.swimm.io/security" target="_blank" @click="openSecurityOverview">
              See our security overview
            </a>
          </div>
        </div>
      </div>
    </SwModal>
    <PaywallModal :show="showPaywall" :entity="PaywallEntity.REPOSITORY" @close="onPaywallClose" />
  </div>
</template>

<script lang="ts">
import { firestoreDeleteField, firestoreTimestamp } from '@/adapters-common/firestore-wrapper';
import GitAuthorizeBanner from '@/common/components/Auth/GitAuthorizeBanner.vue';
import PaywallModal, { PaywallEntity } from '@/common/components/modals/PaywallModal.vue';
import { useInitData } from '@/common/composables/initData';
import { useInitRepository } from '@/common/composables/initRepository';
import { useNavigate } from '@/common/composables/navigate';
import { useRouting } from '@/common/composables/routing';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useStigg } from '@/common/composables/useStigg';
import { swimmApi } from '@/common/swimm-backend-client';
import { SWAL_CONTACT_US_CONTENT, SWAL_LIST_CONTENT } from '@/common/utils/common-definitions';
import { updateSalesforceRepoCreation } from '@/common/utils/salesforce/salesforce';
import { CALENDLY_SALES_LINK, GITHUB_OAUTH_APP_SETTINGS } from '@/config';
import EmptySearchResults from '@/modules/core/components/EmptySearchResults.vue';
import { updateWorkspaceOnFirestore } from '@/modules/core/services/workspace';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { useGetStartedMenu } from '@/modules/get-started/composables/useGetStartedMenu';
import { GetStartedStepIds } from '@/modules/get-started/consts';
import RepoOptionSkeleton from '@/modules/repo/add-repo/components/RepoOptionSkeleton.vue';
import { isNewRepoInitialized } from '@/remote-adapters/init';
import { getRepoDefaultBranch, getUserAvailableRepositories } from '@/remote-adapters/local_repo';
import {
  AddRepoToWorkspaceWebApp400Response,
  AddRepoToWorkspaceWebApp400ResponseReasonEnum,
  CanAddReposToWorkspace200ResponseReasonEnum,
} from '@swimm/backend-client';
import { Notification, useNotificationsStore } from '@swimm/editor';

import {
  GIT_HOSTING_OPTIONS,
  GitProviderInfo,
  GitProviderName,
  HOSTING_NAME_TO_PROVIDER,
  type RemoteRepository,
  SELF_SERVED_SUPPORTED_GIT_HOSTINGS,
  SUPPORTED_GIT_HOSTINGS_REQUIRES_HELP,
  computeRepoMetadataForProvider,
  config,
  escapeRegExpCharacters,
  eventLogger,
  generateUniqueRepoIdForRemote,
  getLoggerNew,
  gitProviderUtils,
  gitwrapper,
  isRepoIdDummyRepo,
  iter,
  pageEvents,
  parseRepoDataFields,
  productEvents,
  state,
} from '@swimm/shared';
import { BaseSelect, Checkbox, Icon, RadioButton, SwModal, SwText, TextField } from '@swimm/ui';
import { AxiosError } from 'axios';
import { debounce, sortBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
import swal from 'sweetalert';
import { computed, defineComponent, nextTick, ref } from 'vue';
import { mapActions, mapGetters, useStore } from 'vuex';

const FEEDBACK_LOCAL_STATE_KEY = 'add-repo-feeback-showed';
const REPOS_BATCH_SIZE = 30;
const REPOS_RENDER_LIMIT = 100;

const logger = getLoggerNew(__modulename);

export default defineComponent({
  components: {
    GitAuthorizeBanner,
    SwText,
    BaseSelect,
    Icon,
    RepoOptionSkeleton,
    SwModal,
    TextField,
    PaywallModal,
    RadioButton,
    Checkbox,
    EmptySearchResults,
  },
  props: {
    show: { type: Boolean, required: true },
    workspaceId: { type: String, default: null },
    title: { type: String, default: 'Connect repository' },
    origin: { type: String, default: '' },
    multiSelect: { type: Boolean, default: false },
  },
  emits: ['reopen-modal', 'repos-added', 'close'],
  setup(props) {
    const store = useStore();
    const { setRepoStateData } = useInitData();
    const analytics = useAnalytics();
    const { markStepAsDone } = useGetStartedMenu();
    const { initRepository } = useInitRepository();
    const {
      enterpriseGitUrl,
      tenantId,
      provider: workspaceProvider,
      providerDisplayName,
      isPendingManualSetup,
      providerLongDisplayName,
      repositories,
      id: workspaceId,
      name: workspaceName,
    } = storeToRefs(useWorkspaceStore());
    const gitAuthorizationStore = useGitAuthorizationStore();
    const { isCurrentWorkspaceAuthorized } = storeToRefs(gitAuthorizationStore);
    const { authorizeProviderWithGit, isProviderAuthorized } = gitAuthorizationStore;
    const { assertOnboardingRoute, assertWelcomeRoute } = useRouting();
    const { getRepoPath } = useNavigate();
    const { user } = storeToRefs(useAuthStore());
    const { createRootFolder } = useFoldersStore();
    const { stiggClient } = useStigg();
    const { addNotification, removeNotification } = useNotificationsStore();
    const isCurrentHostingAuthorized = ref(false);

    async function populateRepoSwmsLists(repoId) {
      await store.dispatch('filesystem/getRepoSwmsLists', { repoId });
    }

    const PROVIDERS_THAT_SUPPORT_ONLY_PUBLIC = [GitProviderName.GitHub, GitProviderName.GitHubEnterprise];
    const modalTitle = computed(() => {
      return isPendingManualSetup.value ? 'Pending Manual Setup' : props.title;
    });
    const selectedRepo = ref('');
    const searchValue = ref('');
    const debouncedSearchValue = ref('');
    const repoLink = ref('');
    const linkError = ref('');
    const gitHosting = ref('');
    const showReplaceProviderContent = ref(false);

    const doesProviderSupportOnlyPublic = computed(() =>
      PROVIDERS_THAT_SUPPORT_ONLY_PUBLIC.includes(workspaceProvider.value)
    );

    function updateSearchTerm() {
      debouncedSearchValue.value = searchValue.value;
    }
    const debouncedSearch = debounce(updateSearchTerm, 500);
    function searchChanged(search) {
      searchValue.value = search;
      debouncedSearch();
    }

    function repoLinkChanged(link) {
      repoLink.value = link;
      linkError.value = '';
    }

    function selectRepo(repo) {
      if (selectedRepo.value && repo.target.value && selectedRepo.value === repo.target.value) {
        selectedRepo.value = '';
      }
    }

    const selectedRepos = ref<RemoteRepository[]>([]);
    const isRepoSelected = function (repo: RemoteRepository) {
      return selectedRepos.value.includes(repo);
    };
    const isRepoSelectionDisabled = function (repo: RemoteRepository): boolean {
      // Disable specific repo selection if selection is limited to one repo and a different repo already selected.
      return !props.multiSelect && !!selectedRepos.value.length && !isRepoSelected(repo);
    };

    const updateRepoSelection = function (repo: RemoteRepository, isChecked: boolean) {
      const repoIndex = selectedRepos.value.findIndex((currentRepo) => repo === currentRepo);
      if (isChecked) {
        if (repoIndex < 0) {
          selectedRepos.value.push(repo);
        }
      } else {
        if (repoIndex >= 0) {
          selectedRepos.value.splice(repoIndex, 1);
        }
      }
    };

    const progressNotification = ref<Notification>(null);
    function getAddingReposProgressMessage(count: number) {
      if (selectedRepos.value.length > 1) {
        return `Adding your repositories (${count}/${selectedRepos.value.length})...`;
      }
      return `Adding your repository...`;
    }
    const addProgressNotification = function (): string {
      progressNotification.value = addNotification(getAddingReposProgressMessage(0), {
        autoClose: false,
        loader: true,
      });
      return progressNotification.value.id;
    };

    const addSuccessNotification = function (repoCount) {
      addNotification(`${repoCount} repositories added successfully.`, { icon: 'check-fill' });
    };

    const addFailureNotification = function (repos: Array<RemoteRepository>) {
      const reposMessage = repos.map((repo) => `${repo.owner}/${repo.name}`).join(', ');
      addNotification(`Failed adding ${reposMessage}.`, { icon: 'warning' });
    };

    const updateProgressNotification = async (counter) => {
      progressNotification.value.text = getAddingReposProgressMessage(counter);
    };
    function onProviderSelect(value) {
      gitHosting.value = value;
    }
    async function showReplaceProviderOptions() {
      analytics.cloudTrack({
        identity: user.value.uid,
        event: productEvents.CLICKED_CHANGE_GIT_PROVIDER_IN_ADD_REPO_MODAL,
        payload: {
          'User ID': user.value.uid,
          Email: user.value.email,
          'Workspace ID': workspaceId.value,
          'Workspace Name': workspaceName.value,
          'Are Repos Connected': hasAddedRepos.value,
        },
      });
      if (hasAddedRepos.value) {
        const element = document.createElement('div');
        element.innerHTML = `To add repos from another provider, disconnect all existing repos first or <a target='_blank' href='${CALENDLY_SALES_LINK}'>contact the Swimm team</a>.`;
        await swal({
          title: 'Only 1 Git hosting platform supported per workspace',
          content: { element },
        });
        return;
      }
      gitHosting.value = gitProviderUtils.getHostingNameByProvider(workspaceProvider.value);
      showReplaceProviderContent.value = true;
    }

    async function onGitHostingChange() {
      const shouldChangeHosting = await swal({
        title: 'Change workspace Git hosting?',
        text: `Are you sure you want to change your workspace's Git hosting provider from ${workspaceLogicalProviderName.value} to ${gitHosting.value}?`,
        dangerMode: true,
        buttons: { cancel: true, confirm: true },
      });
      if (!shouldChangeHosting) {
        return;
      }

      const updatedData = getUpdatedWorkspaceFields();

      const wasSuccessful = await updateWorkspaceOnFirestore(workspaceId.value, updatedData);
      if (!wasSuccessful) {
        await swal({
          title: `Failed to change git hosting`,
          content: { element: SWAL_CONTACT_US_CONTENT() },
        });
        return;
      }

      // Await the call to make sure the event is sent before the page reloads
      await analytics.cloudTrack({
        identity: user.value.uid,
        event: productEvents.CHANGED_GIT_PROVIDER_IN_ADD_REPO_MODAL,
        payload: {
          'User ID': user.value.uid,
          Email: user.value.email,
          'Workspace ID': workspaceId.value,
          'Workspace Name': workspaceName.value,
          'Are Repos Connected': hasAddedRepos.value,
          backofficeCode: eventLogger.SWIMM_EVENTS.WORKSPACE_GIT_PROVIDER_CHANGED.code,
        },
      });

      // Reload the page to apply the changes locally and make sure the new workspace details are fetched
      window.location.reload();
    }

    function getUpdatedWorkspaceFields() {
      let updateDescription = `Changing Git Provider Hosting: ${workspaceLogicalProviderName.value} to ${gitHosting.value} on workspace ${workspaceId.value}.\n`;
      const updatedData = {
        provider: gitHostingToProvider.value,
        modified: firestoreTimestamp(),
        modifier: user.value.uid,
        modifier_name: user.value.displayName,
      };
      if (SUPPORTED_GIT_HOSTINGS_REQUIRES_HELP.includes(gitHosting.value)) {
        updateDescription += `pending_manual_setup:true;`;
        updatedData['pending_manual_setup'] = true;
      } else if (isPendingManualSetup.value) {
        updateDescription += `deleting pending_manual_setup;`;
        updatedData['pending_manual_setup'] = firestoreDeleteField();
      }
      if (enterpriseGitUrl.value) {
        updateDescription += `deleting enterprise_url: "${enterpriseGitUrl.value}";`;
        updatedData['enterprise_url'] = firestoreDeleteField();
      }
      if (tenantId.value) {
        updateDescription += `deleting tenant_id: "${tenantId.value}"`;
        updatedData['tenant_id'] = firestoreDeleteField();
      }
      logger.info(updateDescription);
      return updatedData;
    }

    const connectRepoMessage = computed(() => {
      if (selectedRepos.value.length <= 1) {
        return 'Connect repository';
      }
      return `Connect ${selectedRepos.value.length} repositories`;
    });
    const pendingManualSetup = computed(() => gitProviderUtils.getPendingSetupMessage(providerLongDisplayName.value));
    const isSupportedRequiresHelp = computed(() => SUPPORTED_GIT_HOSTINGS_REQUIRES_HELP.includes(gitHosting.value));
    const workspaceLogicalProviderName = computed(() =>
      gitProviderUtils.getHostingNameByProvider(workspaceProvider.value)
    );
    const isSelectedGitHostingSupported = computed(() => {
      return [...SELF_SERVED_SUPPORTED_GIT_HOSTINGS, ...SUPPORTED_GIT_HOSTINGS_REQUIRES_HELP].includes(
        gitHosting.value
      );
    });
    const canChangeProvider = computed(
      () => isSelectedGitHostingSupported.value && gitHosting.value !== workspaceLogicalProviderName.value
    );

    const hasAddedRepos = computed(() => {
      return repositories.value?.filter((repoId) => !isRepoIdDummyRepo(repoId))?.length > 0;
    });

    const gitHostingToProvider = computed(() => HOSTING_NAME_TO_PROVIDER[gitHosting.value]);

    const changeProviderNote = computed(() =>
      hasAddedRepos.value || isCurrentHostingAuthorized.value
        ? `Different Git hosting?`
        : `Not using ${providerDisplayName.value}? Change Git hosting`
    );
    return {
      createRootFolder,
      GIT_HOSTING_OPTIONS,
      GitProviderInfo,
      GitProviderName,
      PaywallEntity,
      addFailureNotification,
      addProgressNotification,
      addSuccessNotification,
      analytics,
      assertOnboardingRoute,
      assertWelcomeRoute,
      authorizeProviderWithGit,
      canChangeProvider,
      changeProviderNote,
      connectRepoMessage,
      debouncedSearchValue,
      doesProviderSupportOnlyPublic,
      enterpriseGitUrl,
      getRepoPath,
      gitHosting,
      gitHostingToProvider,
      hasAddedRepos,
      initRepository,
      isCurrentHostingAuthorized,
      isCurrentWorkspaceAuthorized,
      isPendingManualSetup,
      isProviderAuthorized,
      isRepoSelected,
      isRepoSelectionDisabled,
      isSelectedGitHostingSupported,
      isSupportedRequiresHelp,
      linkError,
      markStepAsDone,
      modalTitle,
      onGitHostingChange,
      onProviderSelect,
      pendingManualSetup,
      populateRepoSwmsLists,
      providerDisplayName,
      removeNotification,
      repoLink,
      repoLinkChanged,
      searchChanged,
      searchValue,
      selectRepo,
      selectedRepo,
      selectedRepos,
      setRepoStateData,
      showReplaceProviderContent,
      showReplaceProviderOptions,
      stiggClient,
      tenantId,
      updateProgressNotification,
      updateRepoSelection,
      user,
      workspaceLogicalProviderName,
      workspaceProvider,
    };
  },
  data() {
    return {
      loading: true,
      loadingAddingRepos: false,
      remoteRepos: new Array<RemoteRepository>(),
      showPaywall: false,
      scope: 'private',
    };
  },
  computed: {
    ...mapGetters('database', [
      'db_getWorkspace',
      'db_getRepoMetadata',
      'db_getRepository',
      'db_getWorkspaceRepos',
      'db_isWorkspaceAdmin',
    ]),
    workspaceRepos() {
      return this.workspace.repositories.map((repoId) => this.db_getRepoMetadata(repoId)).filter(Boolean);
    },
    ownerHasProject() {
      return gitProviderUtils.providerOwnerHasProject(this.workspaceProvider);
    },
    duplicateNames() {
      if (!this.ownerHasProject) {
        return [];
      }
      const allNames = [...this.remoteRepos.map((repo) => repo.name), ...this.workspaceRepos.map((r) => r.name)];
      return iter.getDuplicates(allNames);
    },
    searchedRemoteRepos() {
      if (this.debouncedSearchValue) {
        const filteredRepos = this.remoteRepos.filter((repo) =>
          new RegExp(escapeRegExpCharacters(this.debouncedSearchValue), 'i').test(this.getSearchName(repo))
        );
        return sortBy(filteredRepos, [this.getDisplayName]);
      }
      // Render only the first REPOS_RENDER_LIMIT items when the user didn't type a query.
      return this.remoteRepos.slice(0, REPOS_RENDER_LIMIT);
    },
    showTooManyResultsMessage() {
      return !this.debouncedSearchValue && this.remoteRepos.length > REPOS_RENDER_LIMIT;
    },
    isAuthorized() {
      return this.isCurrentHostingAuthorized;
    },
    isWorkspaceAdmin() {
      return this.db_isWorkspaceAdmin(this.workspaceId, this.user.uid);
    },
    shouldShowConnectRepo() {
      return this.isWorkspaceAdmin || this.workspace.settings?.allow_non_admin_connect_repos;
    },
    providerButtonString() {
      return `Allow ${
        this.workspaceProvider === GitProviderName.AzureDevops ? 'Azure' : this.providerDisplayName
      } Access`;
    },
    providerScopeSelectionText() {
      return this.doesProviderSupportOnlyPublic ? 'Choose from either public or private repositories.' : '';
    },
    workspace() {
      return this.db_getWorkspace(this.workspaceId);
    },
    workspaceName() {
      return this.workspace?.name || 'Workspace';
    },
    isConnectReposDisabled() {
      return !(this.selectedRepos.length || !!this.selectedRepo || !!this.repoLink);
    },
    isDisableManualRepoInsert() {
      return !!(this.selectedRepo || this.selectedRepos.length);
    },
  },
  watch: {
    async show(newValue) {
      if (newValue) {
        this.isCurrentHostingAuthorized = this.isProviderAuthorized({
          provider: this.workspaceProvider,
          ...(this.enterpriseGitUrl && { gitUrl: this.enterpriseGitUrl, tenantId: this.tenantId }),
        });
        await this.onShowModal();
      } else {
        this.isCurrentHostingAuthorized = false;
      }
    },
  },
  methods: {
    ...mapActions('filesystem', ['setRepoInRepositories']),
    ...mapActions(['updatePreventNavigation']),
    ...mapActions('database', ['fetchRepository', 'updateWorkspaceMetadata']),
    async onShowModal() {
      this.loading = true;
      this.selectedRepos = [];
      this.selectedRepo = '';

      if (this.workspace) {
        try {
          await this.fetchRepositories();
          this.reportViewModal();
        } catch (err) {
          logger.error({ err }, `Failed to load available repositories, error: ${err}`);
          await swal({
            title: `Could not load available repositories from Git hosting provider`,
            content: { element: SWAL_CONTACT_US_CONTENT() },
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchRepositories() {
      if (this.workspaceProvider === GitProviderName.Testing || this.isAuthorized) {
        try {
          const fetchedRepoKeys: string[] = [];
          const driverConfig = this.enterpriseGitUrl ? { baseUrl: this.enterpriseGitUrl, tenantId: this.tenantId } : {};
          const currentRepoBatch = [];
          for await (const repo of getUserAvailableRepositories(this.workspaceProvider, driverConfig)) {
            if (this.workspaceRepos.some((workspaceRepo) => this.isWorkspaceRepoSameAsRemote(workspaceRepo, repo))) {
              continue;
            }
            const repoKey = this.generateRepoKey(repo.name, repo.owner);
            fetchedRepoKeys.push(repoKey);
            const index = this.remoteRepos.findIndex((existingRepo) => this.compareRemoteRepos(repo, existingRepo));
            if (index > -1) {
              this.remoteRepos[index] = repo;
            } else {
              // Add if repo wasn't loaded already.
              if (currentRepoBatch.every((batchRepo) => !this.compareRemoteRepos(repo, batchRepo))) {
                currentRepoBatch.push(repo);
              }
            }
            // Add repos in batches because adding a large number of repos one by one makes the rendering stuck.
            if (currentRepoBatch.length >= REPOS_BATCH_SIZE) {
              this.addReposToRemoteRepos(currentRepoBatch);
              currentRepoBatch.length = 0;
            }
          }
          this.addReposToRemoteRepos(currentRepoBatch);
          this.remoteRepos = this.remoteRepos.filter((repo) =>
            fetchedRepoKeys.includes(this.generateRepoKey(repo.name, repo.owner))
          );
        } catch (err) {
          await swal({
            title: `Could not get repositories from ${this.providerDisplayName}`,
            content: { element: SWAL_CONTACT_US_CONTENT() },
          });
        }
      }
    },
    addReposToRemoteRepos(repos) {
      repos.forEach((repo) => {
        if (this.remoteRepos.every((remoteRepo) => !this.compareRemoteRepos(repo, remoteRepo))) {
          this.remoteRepos.push(repo);
        }
      });
    },
    async authorizationClicked() {
      this.isCurrentHostingAuthorized = this.isProviderAuthorized({
        provider: this.workspaceProvider,
        ...(this.enterpriseGitUrl && { gitUrl: this.enterpriseGitUrl, tenantId: this.tenantId }),
      });
      if (this.isCurrentHostingAuthorized && this.show) {
        await this.onShowModal();
      }
    },
    compareRemoteRepos(firstRepo: RemoteRepository, secondRepo: RemoteRepository) {
      return firstRepo.name === secondRepo.name && firstRepo.owner === secondRepo.owner;
    },
    isWorkspaceRepoSameAsRemote(workspaceRepo: { owner: string; name: string }, remoteRepo: RemoteRepository): boolean {
      if (workspaceRepo.name !== remoteRepo.name) {
        return false;
      }
      if (this.ownerHasProject) {
        const [remoteRepoOwner, remoteRepoProject] = gitProviderUtils.splitOwner(remoteRepo.owner);
        const [workspaceRepoOwner, workspaceRepoProject] = gitProviderUtils.splitOwner(workspaceRepo.owner);
        // if one of the repos does not have projcet (actually, might happen only to the workspace repo)
        // then we check the owner only
        if (!workspaceRepoProject || !remoteRepoProject) {
          return remoteRepoOwner === workspaceRepoOwner;
        }
        return workspaceRepo.owner === remoteRepo.owner;
      }
      return workspaceRepo.owner === remoteRepo.owner;
    },
    generateRepoKey(name: string, owner: string) {
      return `${name}-${owner}`;
    },
    getDisplayName(repo: RemoteRepository) {
      return `${repo.owner}/${repo.name}`;
    },
    getSearchName(repo: RemoteRepository) {
      return `${repo.owner}/${repo.name}`;
    },
    async addRepos() {
      this.loadingAddingRepos = true;
      if (!this.multiSelect) {
        const repo = this.remoteRepos.find((repo) => {
          return this.generateRepoKey(repo.name, repo.owner) === this.selectedRepo;
        });
        if (!repo) {
          // Edge case when a repos fetch didn't contain the selected repo and override older results.
          logger.error(
            `Failed to add repo to workspace ${this.workspaceId}, couldn't to find selected repo ${this.selectedRepo} in repos list.`
          );
          await swal({
            title: 'Failed to add repo',
            text: `Something went wrong, please try again.`,
          });
          return;
        }
        this.selectedRepos = [repo];
      }

      if (!this.selectedRepos.length && this.repoLink) {
        try {
          const repoMetadata = computeRepoMetadataForProvider(this.repoLink, this.workspaceProvider);
          if (!repoMetadata.name || !repoMetadata.owner) {
            throw new Error('Invalid provider URL');
          }

          const repoData = await gitwrapper.getRepoRemoteData({
            provider: this.workspaceProvider,
            repoName: repoMetadata.name,
            owner: repoMetadata.owner,
            api_url: this.enterpriseGitUrl,
            tenant_id: this.tenantId,
          });
          this.selectedRepos = [repoData];
        } catch (err) {
          this.linkError = 'Provided URL is not a valid repo URL';
          this.loadingAddingRepos = false;
          logger.error(
            { err },
            `Failed to add repo from URL to workspace ${this.workspaceId}, url: ${this.repoLink}, error: ${err}`
          );
          return;
        }
      }

      try {
        this.analytics.track(productEvents.CLICKED_ADD_REPOS, {
          'Repo Count': this.selectedRepos.length,
        });
        const privateReposCount = this.selectedRepos.filter((repo) => repo.isPrivate).length;
        const canAdd = await this.checkIfCanAddRepos(privateReposCount);
        if (!canAdd) {
          return;
        }
        const progressNotificationId = this.addProgressNotification();
        const repoIds = [];
        const failedRepos = [];
        let addingReposProgressCounter = 0;
        // Close the modal, the progress is shown in a notification.
        this.$emit('close');
        await Promise.allSettled(
          this.selectedRepos.map(async (repo) => {
            const addRepoResult = await this.addRepo(repo);
            logger.info(`Called addRepo for ${repo.owner}/${repo.name} => ${JSON.stringify(addRepoResult)}`);
            addingReposProgressCounter++;
            this.updateProgressNotification(addingReposProgressCounter);
            if (addRepoResult.success) {
              repoIds.push(addRepoResult.repoId);
            } else if (addRepoResult.success === false) {
              failedRepos.push({ repo, addRepoResult });
            }
          })
        );
        // after adding the repos, we fetch the workspace metadata
        // so the repositories array will be updated
        await this.updateWorkspaceMetadata({ workspaceId: this.workspaceId });
        if (this.selectedRepos.length === 1 && failedRepos.length === 0) {
          await this.loadAndNavigateToRepo(repoIds[0]);
        }
        await nextTick();
        this.removeNotification(progressNotificationId);
        const errorMessages = failedRepos
          .map(({ repo, addRepoResult }) => {
            if (addRepoResult.repoInOtherWorkspace) {
              return `Repo ${repo.name} has already been added to another workspace. To add it to this workspace, remove it from the other workspace first.`;
            }
            if (addRepoResult.alreadyInWorkspace) {
              return `Repo ${repo.name} has already been added to the workspace - please refresh the page.`;
            }
            return null;
          })
          .filter(Boolean);
        if (errorMessages.length) {
          await swal({
            title: errorMessages.length === 1 ? 'Repo already exists' : 'Repos already exist',
            content: { element: SWAL_LIST_CONTENT(errorMessages) },
          });
        }
        const failedLimit = failedRepos.filter(({ addRepoResult }) => addRepoResult.reachedReposLimit);
        if (failedLimit.length > 0) {
          this.analytics.cloudTrack({
            identity: this.user.uid,
            event: productEvents.PRIVATE_REPO_LIMIT_REACHED_PAYWALL,
            payload: {
              'User ID': this.user.uid,
              Email: this.user.email,
              'Workspace ID': this.workspaceId,
              'Workspace Name': this.workspaceName,
              Type: 'Popup',
              Paywall: true,
              'Repo ID': failedLimit[0].addRepoResult.repoId,
            },
          });
          this.showPaywall = true;
          await nextTick();
        }

        if (failedRepos.length) {
          this.addFailureNotification(failedRepos.map(({ repo }) => repo));
        }
        if (repoIds.length) {
          await this.markStepAsDone(GetStartedStepIds.CONNECT_A_REPO);
          if (!failedRepos.length && repoIds.length > 1) {
            this.addSuccessNotification(repoIds.length);
          }
          this.$emit('repos-added', { repoIds });
        }
      } catch (err) {
        logger.error({ err }, `Couldn't add repos to workspace ${this.workspaceId}. Error: ${err}`);
      } finally {
        this.loadingAddingRepos = false;
      }
    },
    async checkIfCanAddRepos(privateReposCount: number): Promise<boolean> {
      let showError = false;
      try {
        const canAddResp = await swimmApi.canAddReposToWorkspace(this.workspaceId, privateReposCount);
        if (!canAddResp.data.canAdd) {
          if (canAddResp.data.reason === CanAddReposToWorkspace200ResponseReasonEnum.ReachedReposLimit) {
            this.analytics.cloudTrack({
              identity: this.user.uid,
              event: productEvents.PRIVATE_REPO_LIMIT_REACHED_PAYWALL,
              payload: {
                'User ID': this.user.uid,
                Email: this.user.email,
                'Workspace ID': this.workspaceId,
                'Workspace Name': this.workspaceName,
                Type: 'Popup',
                Paywall: true,
              },
            });
            this.showPaywall = true;
            return false;
          } else {
            // this should not happen
            logger.error(`Got unexpected canAddResp.data = ${JSON.stringify(canAddResp.data)}`);
            showError = true;
          }
        }
      } catch (err) {
        logger.error({ err }, `Failed calling canAddReposToWorkspace for workspace ${this.workspaceId}`);
        showError = true;
      }
      if (showError) {
        await swal({
          title: 'Failed to add repos',
          text: `Something went wrong, please try again.`,
        });
        return false;
      }
      return true;
    },
    async getOrComputeRepoId(repo: RemoteRepository): Promise<string> {
      const repoData = parseRepoDataFields({
        provider: this.workspaceProvider,
        repoName: repo.name,
        owner: repo.owner,
        ...(repo.defaultBranch ? { defaultBranch: repo.defaultBranch } : {}),
        ...(this.enterpriseGitUrl ? { api_url: this.enterpriseGitUrl, tenant_id: this.tenantId ?? undefined } : {}),
      });

      const isInitializedResult = await isNewRepoInitialized(repoData);
      if (isInitializedResult.initialized) {
        return isInitializedResult.repoId;
      }
      return generateUniqueRepoIdForRemote(repo.cloneUrl);
    },
    async addRepo(repo: RemoteRepository): Promise<
      | { success: true; repoId: string }
      | {
          success: false;
          repoId: string;
          reachedReposLimit: boolean;
          repoInOtherWorkspace: boolean;
          alreadyInWorkspace: boolean;
        }
    > {
      let repoId = '';
      try {
        repoId = await this.getOrComputeRepoId(repo);
        const result = await swimmApi.addRepoToWorkspaceWebApp({
          repoId,
          workspaceId: this.workspaceId,
          remoteRepository: repo,
          provider: this.workspaceProvider,
        });
        if (result.data.isNewRepo) {
          await this.createRootFolder(repoId);
          updateSalesforceRepoCreation({ workspaceId: this.workspaceId, repoId });
        }
        await this.fetchRepository({ repoId });
        await this.setRepoStateData(repoId);
        const repoMetadata = this.db_getRepoMetadata(repoId);
        await this.setRepoInRepositories(repoMetadata);
        this.analytics.cloudTrack({
          identity: this.user.uid,
          event: productEvents.REPO_ADDED_TO_WORKSPACE,
          payload: {
            'Repo ID': repoId,
            'Workspace ID': this.workspaceId,
            'Workspace Name': this.workspaceName,
            'Repo Name': repo.name,
            'Repo Type': repo.isPrivate ? 'private' : 'public',
          },
        });
        return { success: true, repoId };
      } catch (err) {
        logger.error(
          { err },
          `Failed to add repository ${repo?.owner}/${repo?.name} from ${repo?.htmlUrl} to workspace ${
            this.workspaceId
          } , error: ${err} ${JSON.stringify(err?.response?.data ?? '')}`
        );
        const reason =
          err instanceof AxiosError && err.response?.data
            ? (err.response.data as AddRepoToWorkspaceWebApp400Response).reason
            : undefined;
        return {
          success: false,
          repoId,
          alreadyInWorkspace: reason === AddRepoToWorkspaceWebApp400ResponseReasonEnum.AlreadyInWorkspace,
          reachedReposLimit: reason === AddRepoToWorkspaceWebApp400ResponseReasonEnum.ReachedReposLimit,
          repoInOtherWorkspace: reason === AddRepoToWorkspaceWebApp400ResponseReasonEnum.RepoInOtherWorkspace,
        };
      }
    },
    async loadAndNavigateToRepo(repoId: string) {
      const defaultBranchResult = await getRepoDefaultBranch({
        repoId,
      });
      if (defaultBranchResult.code !== config.SUCCESS_RETURN_CODE) {
        return;
      }
      const repoRoute = this.getRepoPath(repoId, defaultBranchResult.branch);
      await this.populateRepoSwmsLists(repoId);
      if (this.assertWelcomeRoute()) {
        return;
      } else if (this.assertOnboardingRoute()) {
        this.updatePreventNavigation(false);
        await this.$router.replace(`${repoRoute}/docs/new`);
      } else {
        await this.$router.replace(repoRoute);
      }
    },
    async onCloseModalWithoutAddingRepo() {
      if (this.showReplaceProviderContent) {
        this.showReplaceProviderContent = false;
      }
      this.$emit('close');
    },
    async shouldShowFeebackModal() {
      if (this.assertWelcomeRoute()) {
        return false;
      }
      const isWorkspaceHasRepos = Boolean(this.db_getWorkspaceRepos(this.workspaceId).length);
      if (isWorkspaceHasRepos) {
        return false;
      }
      const isAlreadyShown = await state.get({ key: FEEDBACK_LOCAL_STATE_KEY });
      if (isAlreadyShown) {
        return false;
      }
      if (!this.isWorkspaceAdmin) {
        return false;
      }
      await state.set({ key: 'add-repo-feeback-showed', value: true });
      return true;
    },
    async onPaywallClose({ isUpgrade }) {
      this.showPaywall = false;
      if (isUpgrade) {
        await this.onCloseModalWithoutAddingRepo();
      }
    },
    onManageScopeClick() {
      window.open(GITHUB_OAUTH_APP_SETTINGS, '_blank');
      this.addFocusListenerToFetchRepositories();
    },
    addFocusListenerToFetchRepositories() {
      // It takes few seconds for GitHub to update the permission status - timeout is necessary
      const TIME_FOR_GITHUB_PERMISSIONS_SCOPE_TO_UPDATE = 4000;
      window.addEventListener(
        'focus',
        () => {
          this.loading = true;
          setTimeout(async () => {
            await this.fetchRepositories();
            this.loading = false;
          }, TIME_FOR_GITHUB_PERMISSIONS_SCOPE_TO_UPDATE);
        },
        { once: true }
      );
    },
    openSecurityOverview() {
      this.analytics.track(productEvents.CLICKED_SECURITY_OVERVIEW, {
        Origin: this.origin,
        'Origin URL': this.$route.fullPath,
        Context: 'Connect a Repo Popup',
        'Workspace ID': this.$route.params.workspaceId,
        'Workspace Name': this.workspaceName,
      });
    },
    reportViewModal() {
      const event = this.isAuthorized ? pageEvents.VIEW_SELECT_REPOSITORY : pageEvents.GITHUB_ACCESS_PERMISSIONS;
      this.analytics.pageVisit(event, {
        Origin: this.origin,
        'Workspace ID': this.workspaceId,
        'Workspace Name': this.workspaceName,
        Type: 'Popup',
      });
    },
  },
});
</script>

<style scoped lang="postcss">
.content {
  display: flex;
  padding: 24px;
  width: 488px;
  flex-direction: column;
}

.information-text {
  padding-bottom: 24px;
  font-family: var(--fontfamily-main);
  font-weight: 400;
  color: var(--text-color-primary);
  line-height: 1.5rem;
}

.azure-oauth-help-section {
  padding-top: 24px;
}

.azure-oauth-help {
  color: var(--text-color-link);
  line-height: 1rem;
  text-decoration: underline;
}

.repo-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;

  .repo-name {
    display: flex;
    align-items: center;
    gap: var(--space-xs);
  }
}

.help-text,
.one-provider-support,
.change-provider-text {
  margin-left: 8px;
  font-size: var(--subtitle-S);
  color: var(--text-color-secondary);
  line-height: 1rem;
  cursor: pointer;
}

.one-provider-support {
  margin-left: 0;
}

.help-text:hover {
  text-decoration: underline;
}

.change-provider-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.enterprise-banner {
  font-size: var(--body-XS);
  text-align: left;
  padding: 12px var(--space-sm);
  margin-top: var(--space-xs);
}

.error-message {
  font-size: var(--body-XS);
  color: var(--text-color-error);
  margin-top: 8px;
  text-align: left;
}

.replace-provider-btn {
  margin-top: 18px;
  margin-bottom: 12px;
}

.repo-scope {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  border-radius: 6px;
  padding: 0 var(--space-sm);
  background: var(--color-surface);
}

.repos-list {
  overflow: auto;
  height: 234px;
}

.repo-icon {
  width: 14px;
  height: 14px;
}

.subtitle {
  margin-bottom: 8px;
}

.select-scope {
  border: 1px solid var(--color-border-default);
  border-radius: 8px;
}

.scope-item {
  padding: 16px;
  border-bottom: 1px solid var(--color-border-default);
}

.scope-item:last-of-type {
  border-bottom: none;
}

.scope-subtext {
  font-size: var(--body-XS);
  color: var(--text-color-secondary);
}

.info-container {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: var(--space-base);
}

.search-container {
  padding: 80px 0;
}

.no-results {
  padding: 40px 0;

  .description {
    margin-top: 16px;
  }
}

.repo-search :deep(.loader) {
  --loader-size: 24px !important;
}

.auth-button.wrapper {
  padding: 0;
  width: 210px;
}

.manual-repo {
  display: flex;
  align-items: baseline;
  margin-top: var(--space-md);
  color: var(--text-color-secondary);

  .manual-repo-test {
    margin-left: var(--space-xsmall);
  }
}
</style>
