<script setup lang="ts">
import { computedAsync } from '@vueuse/core';
import { useAppModalsStore } from '@/modules/core/stores/modals-store';
import {
  BaseButton,
  BaseDropdown,
  BaseHeading,
  BaseIcon,
  BaseInput,
  BaseLayoutGap,
  BaseLoading,
  BaseProse,
  NodeTree,
} from '@swimm/reefui';
import { FolderTree, WorkspaceDocumentTemplate } from '@swimm/shared';
import { SwModal } from '@swimm/ui';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import { useFsWrapper } from '@/modules/editor/tiptapEditor/compositions/fsWrapper';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

const props = withDefaults(
  defineProps<{
    repoId: string;
    branch: string;
  }>(),
  {
    repoId: '',
    branch: null,
  }
);

const { fsWrapper } = useFsWrapper();

const modalStore = useAppModalsStore();
const { documentTemplates } = storeToRefs(useWorkspaceStore());
const { showProcessToDocModal } = storeToRefs(modalStore);
const { closeProcessToDocModal } = modalStore;

const selectedProcess = ref<WorkspaceDocumentTemplate>();
const selectedNodes = ref([]);
const docTitle = ref<string>('');
const query = ref<string>('');

const templates = computed(() => {
  if (documentTemplates.value instanceof Map) {
    return Array.from(documentTemplates.value.values()).map((template, index) => ({
      id: index,
      label: template.name,
      name: template.name,
      link: '',
    }));
  }
  return [];
});

const folderTree = computedAsync<FolderTree | null>(async () => {
  if (props.repoId == null || props.branch == null || !showProcessToDocModal.value) {
    return null;
  }
  return await getRepoFolderTree(props.repoId, props.branch, false);
});

const loadingTree = ref<boolean>(true);

async function getRepoFolderTree(repoId: string, branch: string, isCrossRepo: boolean): Promise<FolderTree> {
  await fsWrapper.populateRepoFolderTree({ repoId: repoId, branch, isCrossRepo });
  const folderTree = await fsWrapper.getRepoFolderTree(repoId);
  return folderTree;
}

function addSelectedNode(node: FolderTree) {
  selectedNodes.value.push(node);
}
function removeSelectedNode(node: FolderTree) {
  selectedNodes.value = selectedNodes.value.filter((n) => n.path !== node.path);
}

function selectDocumentTemplate(templateName: string) {
  const template = documentTemplates.value.get(templateName);
  selectedProcess.value = template;
}

watch(
  () => [folderTree.value, documentTemplates.value],
  (value) => {
    if (value[0]) {
      loadingTree.value = false;
    }
  },
  { immediate: true }
);

function onSubmit() {
  const title = docTitle.value;
  const process = selectedProcess.value;
  const nodesToProcess = selectedNodes.value.filter((node) => node.type === 'file');

  title;
  process;
  nodesToProcess;

  // Implement the submit logic here
}
</script>

<template>
  <div>
    <SwModal :padded="false" :show-modal="showProcessToDocModal" @close="closeProcessToDocModal">
      <template #header>
        <BaseLayoutGap alignment="left">
          <BaseIcon name="magic" />
          <BaseProse weight="bolder"> Process to doc </BaseProse>
        </BaseLayoutGap>
      </template>
      <div class="prs-modal">
        <BaseLayoutGap direction="column" size="small" alignment="stretch">
          <BaseHeading :level="4"> Template </BaseHeading>
          <BaseDropdown
            @selected="(e) => selectDocumentTemplate(e.name)"
            placeholder="Select a process"
            :options="templates"
            :footer="true"
          />
          <BaseHeading :level="4"> Input Folder </BaseHeading>
          <div class="tree-container">
            <template v-if="loadingTree"> <BaseLoading /> </template>
            <template v-else>
              <NodeTree
                :query="query"
                :node="folderTree"
                :level-offset="1"
                :selected-nodes="selectedNodes"
                selectable
                selectable-folders
                @selected-nodes:add="addSelectedNode"
                @selected-nodes:remove="removeSelectedNode"
              />
            </template>
          </div>
          <BaseHeading :level="4"> Document Title </BaseHeading>
          <BaseInput v-model="docTitle" type="text" placeholder="Title" required />
        </BaseLayoutGap>
        <BaseLayoutGap direction="row" alignment="right" class="buttons-container">
          <BaseButton @click="onSubmit" :disabled="false">Submit</BaseButton>
        </BaseLayoutGap>
      </div>
    </SwModal>
  </div>
</template>

<style scoped>
.prs-modal {
  padding: var(--space-medium);
  width: 540px;
  display: flex;
  flex-direction: column;
  gap: var(--space-small);
  overflow-y: auto;
}

.tree-container {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
