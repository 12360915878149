<script setup lang="ts">
import { type PropType, computed, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { PageSource } from '@swimm/shared';
import ShareButton from '@/common/components/TopBar/ShareButton.vue';
import TopBar from '@/common/components/TopBar/TopBar.vue';
import DocumentationOptionsMenu from '@/common/components/organisms/DocumentationOptionsMenu.vue';
import { useNavigate } from '@/common/composables/navigate';
import { useRouting } from '@/common/composables/routing';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import BatchCommitButton from '@/modules/batch-commit/components/BatchCommitButton.vue';
import DraftsSaveIndicator from '@/modules/drafts3/components/DraftsSaveIndicator.vue';
import { confirmDraftDeletion } from '@/modules/drafts3/discard-draft-confirmations';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import EditorPane from '../components/EditorPane.vue';
import { useInitData } from '@/common/composables/initData';
import MoveFolderModal from '@/modules/folders/components/MoveFolderModal.vue';
import { DocumentationTypes } from '@/common/consts';
import { useFoldersStore } from '@/modules/folders/store/folders';
import UserProfile from '@/common/components/organisms/UserProfile.vue';

const props = defineProps({
  editable: { type: Boolean, required: true },
  embedded: { type: Boolean },
  workspaceId: { type: String, required: true },
  repoId: { type: String, required: true },
  unitId: { type: String, required: true },
  branch: { type: String, required: true },
  committed: { type: Boolean },
  source: { type: String as PropType<PageSource>, default: null },
});

const route = useRoute();
const { routeBackFromDoc } = useRouting();
const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();
const { getItemParentFolder3 } = useFoldersStore();

const drafts3Store = useDrafts3Store();
const { setWorkspaceData } = useInitData();
const store = useStore();

const loadingRepos = ref(false);
const showMoveFolderModal = ref(false);
const resourceToMove = ref();

const isWorkspaceSidebarCollapsedByDefault = computed(() =>
  store.getters['database/db_isWorkspaceSidebarCollapsedByDefault'](props.workspaceId)
);

const isNewDraft = computed(() => {
  const draft = drafts3Store.drafts?.get(props.unitId);
  return !!draft?.isNew;
});

const folderIdToUse = computed(() => {
  const folder = getItemParentFolder3(props.unitId, props.repoId);
  if (folder?.is_root) {
    return null;
  }
  return folder?.id;
});

watchEffect(async () => {
  // TODO: this is due hack we did for performance issue for workspace with many repos
  // Currently the workspace sidebar doing the setWorkspaceData call but if it is collapsed there is no trigger to it
  // Without this call the snippet studio will not work since it is relay that the repos are already fetch
  // Notice: this watch effect might be triggered even if the workspaceId was not changed
  if (isWorkspaceSidebarCollapsedByDefault.value) {
    loadingRepos.value = true;
    // In this specific case since this is to handle many repos use case.
    // Fetching each repo seperatly and each repo swimmers and lifeguard data sometimes takes long
    // In order to display the editor, swimmers information is not nessary so we can skip it and fetch all repositories more efficently
    await setWorkspaceData(props.workspaceId, false, props.repoId);
    loadingRepos.value = false;
  }
});
const getRepoSwmsLists = (args?) => store.dispatch('filesystem/getRepoSwmsLists', args);

watch(
  () => ({ repoId: props.repoId, branch: props.branch }),
  async ({ repoId, branch }) => {
    if (repoId && branch) {
      await getRepoSwmsLists({ repoId, branch });
    }
  },
  { immediate: true }
);

// Editing the document should be disabled during committing
const blockEditing = computed(() => !props.editable || drafts3Store.committing);

function shouldOpenCheatSheet(): boolean {
  return route.name === 'OnboardingDoc';
}

async function navigateToEdit(preserveSource?: boolean): Promise<void> {
  // TODO Figure out if we can call router.push with a route props object instead of
  // navigateToPageAndTerminateWorker, where the worker is handled transparently
  // by the router or layout component.
  return navigateToPageAndTerminateWorker({
    newRoute: `${getRepoPath()}/docs/${props.unitId}/edit`,
    query: preserveSource ? { source: props.source } : undefined,
  });
}

function goToCommitted() {
  // TODO Figure out if we can call router.push with a route props object instead of
  // navigateToPageAndTerminateWorker, where the worker is handled transparently
  // by the router or layout component.
  return navigateToPageAndTerminateWorker({
    newRoute: `${getRepoPath()}/docs/${props.unitId}/comitted`,
  });
}

async function discard(): Promise<void> {
  if (await confirmDraftDeletion()) {
    await drafts3Store.discardDraft(props.unitId);
    await routeBackFromDoc();
  }
}

function changeFolder() {
  resourceToMove.value = [
    {
      isNew: isNewDraft,
      documentationType: DocumentationTypes.DOC,
      id: props.unitId,
      folderId: folderIdToUse.value,
    },
  ];
  showMoveFolderModal.value = true;
}
</script>

<template>
  <TopMenuLayout
    :key="unitId"
    :should-open-keyboard-shortcut-on-start="shouldOpenCheatSheet"
    :should-lift-bottom-icons="editable"
    :show-knowledge-icon="!embedded"
    :loading="loadingRepos"
  >
    <template #topBar>
      <TopBar :show-workspace-breadcrumb="false" :hide-breadcrumbs="embedded">
        <template #breadcrumbs v-if="editable">
          <DraftsSaveIndicator />
        </template>
        <template v-if="embedded">
          <UserProfile :embedded="embedded" />
        </template>
        <template v-else-if="!editable">
          <DocumentationOptionsMenu :show-go-to-committed="false" :show-discard="false" @change-folder="changeFolder" />
          <ShareButton entity="DOC" :repo-id="repoId" :entity-id="unitId" />
          <div class="edit-doc-button" data-testid="edit-doc-button" @click="() => navigateToEdit()">Edit doc</div>
        </template>
        <template v-else>
          <DocumentationOptionsMenu
            :show-go-to-committed="!isNewDraft"
            :show-discard="true"
            @committed-click="goToCommitted"
            @discard-click="discard"
            :is-new-draft="isNewDraft"
            :unit-id="unitId"
            @change-folder="changeFolder"
          />
          <ShareButton entity="DOC" :repo-id="repoId" :entity-id="unitId" />
          <BatchCommitButton v-if="editable" :repo-id="repoId" :workspace-id="workspaceId" :branch="branch" />
        </template>
      </TopBar>
      <MoveFolderModal
        v-if="showMoveFolderModal"
        :show="showMoveFolderModal"
        :repo-id="repoId"
        @close="showMoveFolderModal = false"
        :items="resourceToMove"
      />
    </template>

    <template #content>
      <EditorPane
        :editable="editable"
        :embedded="embedded"
        :workspace-id="workspaceId"
        :repo-id="repoId"
        :unit-id="unitId"
        :branch="branch"
        :committed="committed"
        :should-increment-view-count="!editable"
        :block-editing="blockEditing"
        :source="source"
        @navigate-to-edit="navigateToEdit"
      />
    </template>
  </TopMenuLayout>
</template>

<style scoped lang="postcss">
.edit-doc-button {
  cursor: pointer;
  padding: var(--space-base);
  border-radius: 4px;

  &:hover {
    background-color: var(--color-hover);
  }
}
</style>
